import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import main from "../../images/main.mp4";

function Outlook2023({ initialLoad }) {
    const navigate = useNavigate();
    const [right, setRight] = useState(false);
    return (
        <motion.div
            className=" h-full"
            initial={initialLoad ? {} : { x: window.innerWidth }}
            animate={
                initialLoad ? {} : { x: 0, transition: { ease: "linear" } }
            }
            exit={
                right
                    ? { x: -window.innerWidth, transition: { duration: 0.4 } }
                    : { x: window.innerWidth, transition: { duration: 0.4 } }
            }
        >
            <video
                className="lg:w-full h-full  absolute top-0 object-cover -z-20"
                src="https://firebasestorage.googleapis.com/v0/b/everidoor-917c9.appspot.com/o/beach.mp4?alt=media&token=6d2c9bed-e80c-4af0-bef7-d634928c000c"
                controls={false}
                muted
                autoPlay={"autoplay"}
                preLoad="auto"
                loop
                type="video/mp4"
            />
            <motion.div
                className="bg-black h-full w-full absolute inset-0"
                initial={{ opacity: 1, x: -20 }}
                animate={{
                    zIndex:-1,
                    opacity: 0,
                    x: 0,
                    transition: { delay: 0.5 },
                }}
            ></motion.div>
            <button
                type="button"
                className="text-black p-2 px-4 bg-white rounded-sm fixed bottom-16 lg:right-16 right-12 opacity-70 hover:opacity-100"
                onClick={() => {
                    setRight(true);
                    navigate(
                        "/Outlook2023/Navigating-the-Competitive-Landscape"
                    );
                }}
            >
                {">"}
            </button>
            <button
                type="button"
                className="text-black p-2 px-4 bg-white rounded-sm fixed bottom-16 lg:right-28 right-24 opacity-70 hover:opacity-100"
                onClick={() => navigate("/Outlook2023")}
            >
                {"<"}
            </button>
            <motion.div
                className="px-10 lg:px-16 py-32"
                initial={{ opacity: 0, x: -20 }}
                animate={{
                    opacity: 1,
                    x: 0,
                    transition: { delay: 1 },
                }}
            >
                <h1 className="md:text-8xl text-5xl text-white Ramillas tracking-wider">
                    LUXURY
                </h1>
                <h1 className="md:text-8xl text-4xl text-white Ramillas tracking-wider">
                    OUTLOOK
                </h1>
                <h1 className="md:text-4xl text-5xl text-white Ramillas tracking-wider">
                    2025
                </h1>
                <p className="text-white md:text-xl lg:w-1/3 mt-16">
                    A premier out-of-home advertising solutions provider
                    dedicated to serving the marketing needs of upscale and
                    exclusive brands.
                </p>
                {/* <button
                    type="button"
                    className="bg-white mt-16 text-black text-md p-2 px-6"
                >
                    START READING
                </button> */}
            </motion.div>
        </motion.div>
    );
}

export default Outlook2023;
