import React, { useState } from "react";
import Header from "./Components/Header";
import { CountryDropdown } from "react-country-region-selector";
import Footer from "./Components/Footer";
import { useForm, ValidationError } from "@formspree/react";

function Contact() {
  const [country, setCountry] = useState("");
  const [state, handleSubmit] = useForm("mzblvrjb");

  return (
    <div>
      <Header />
      {/* Hero */}
      <section>
        <div className="flex justify-between lg:flex-row flex-col-reverse md:mt-0 mt-20">
          <div className="lg:mt-0 mt-8">
            <h1 className="tracking-wider px-8 text-[11px] font-semibold opacity-75">
              <span className="text-gray-400">CONTACT</span> | CONCIERGE ENQUIRY
            </h1>
            <h1 className="Ramillas text-4xl xl:mt-16 lg:mt-8 md:px-28 px-8 mt-8">
              Concierge Enquiry
            </h1>
            <p className="xl:mt-8 mt-4 md:w-[80%] w-full md:px-28 px-8">
              Please provide your basic contact details. One of our consultants
              will contact you shortly via e-mail or telephone.
            </p>
          </div>
          <div className="lg:w-[45vw]">
            <img
              src="contact.jpg"
              alt=""
              className="lg:h-full h-[300px] w-full object-cover"
            />
          </div>
        </div>
        <div className="bg-[#f7f7f7] h-full lg:w-[92%] w-full lg:mt-0 mt-8 lg:py-0 py-8">
          <div className="bg-white shadow-[0_0px_20px_-5px_rgba(0,0,0,0.3)] lg:w-[70%] w-[90%] lg:ml-28 lg:mx-none mx-auto h-full lg:-translate-y-[2vw] xl:-translate-y-[7vw] 2xl:-translate-y-[10vw] -translate-y-0 md:p-24 p-8">
            <h1 className="Ramillas text-3xl">Contact information</h1>
            <form
              action="https://formspree.io/f/mzblvrjb"
              method="POST"
              onSubmit={handleSubmit}
              className="mt-8 text-sm"
            >
              <div>
                <p>Salutation</p>
                <select
                  name="Salutation"
                  id="Salutation"
                  className="border-b-2 md:w-[45%] w-full mt-4 focus:outline-none py-1 text-gray-600"
                  required
                >
                  <option value="">Please select</option>
                  <option value="Mr.">Mr.</option>
                  <option value="Mr.">Ms.</option>
                  <option value="Mrs.">Mrs.</option>
                </select>
              </div>
              <div className="mt-8 md:flex w-full">
                <div className="w-full">
                  <p>First Name</p>
                  <input
                    type="text"
                    name="First Name"
                    id="fname"
                    placeholder="Enter your first name"
                    className="md:w-[90%] w-full py-1 mt-4 border-b-2 focus:outline-none text-gray-600"
                    required
                  />
                </div>
                <div className="w-full md:mt-0 mt-4">
                  <p>Last Name</p>
                  <input
                    type="text"
                    name="Last Name"
                    id="lname"
                    placeholder="Enter your last name"
                    className="md:w-[90%] w-full py-1 mt-4 border-b-2 focus:outline-none text-gray-600"
                    required
                  />
                </div>
                <div className="w-full md:mt-0 mt-4">
                  <p>Property/Organisation</p>
                  <input
                    type="text"
                    name="Last Name"
                    id="lname"
                    placeholder="Enter your Property/Organisation"
                    className="md:w-[90%] w-full py-1 mt-4 border-b-2 focus:outline-none text-gray-600"
                    required
                  />
                </div>
              </div>
              <div className="mt-8">
                <p>I have a specific concierge question</p>
                <textarea
                  name="Message"
                  id="message"
                  cols="30"
                  rows="4"
                  placeholder="Type your question..."
                  className="border-2 focus:outline-none md:w-[95%] w-full mt-4 p-2"
                  required
                ></textarea>
                <ValidationError
                  prefix="Message"
                  field="message"
                  errors={state.errors}
                />
                <p className="text-sm text-gray-500">
                  Text limit 5,000 characters
                </p>
              </div>
              <div className="mt-8 md:flex">
                <div className="md:w-1/2">
                  <p>Country you currently reside in</p>
                  <CountryDropdown
                    value={country}
                    onChange={(val) => setCountry(val)}
                    classes="focus:outline-none md:w-[90%] w-full border-b-2 mt-4 py-1 text-gray-600"
                    required
                  />
                </div>
                <div className="md:w-1/2 md:mt-0 mt-4">
                  <p>Telephone Number</p>
                  <input
                    type="text"
                    name="Telephone"
                    id="Telephone"
                    placeholder="Enter your phone number"
                    className="md:w-[90%] w-full py-1 mt-4 border-b-2 focus:outline-none text-gray-600"
                    required
                  />
                </div>
              </div>
              <div className="md:flex mt-8">
                <div className="md:w-1/2">
                  <p>E-Mail address</p>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter your E-Mail Address"
                    className="md:w-[90%] w-full py-1 mt-4 border-b-2 focus:outline-none text-gray-600"
                    required
                  />
                  <ValidationError
                    prefix="Email"
                    field="email"
                    errors={state.errors}
                  />
                </div>
                <div className="md:w-1/2 md:mt-0 mt-4">
                  <p>How did you hear about Everidoor?</p>
                  <select
                    name="Unfamiliar"
                    id="hear"
                    className="border-b-2 md:w-[90%] w-full mt-4 focus:outline-none py-1 text-gray-600"
                    required
                  >
                    <option value="">Please select</option>
                    <option value="Article">Article</option>
                    <option value="Newspaper">Newspaper</option>
                    <option value="Blog">Blog</option>
                    <option value="Social Media">Social Media</option>
                    <option value="Advertisement">Advertisement</option>
                    <option value="Others">Others</option>
                  </select>
                </div>
              </div>
              <div className="mt-16 flex gap-4 items-start">
                <input
                  type="checkbox"
                  name="checkbox"
                  id="checkbox"
                  className="h-4 w-4 rounded-sm cursor-pointer mt-2"
                  required
                />
                <p className="cursor-pointer">
                  Please keep me updated and send me information and news about
                  Everidoor and its group companies, event invitations, press
                  references, and publications via e-mail or mail.
                </p>
              </div>
              <div className="w-full">
                <button
                  disabled={state.succeeded}
                  type="submit"
                  className={`py-4 px-16 text-gray-500 border-2 border-gray-500 font-semibold text-sm mt-8 relative left-[50%] -translate-x-[50%] ${
                    state.succeeded
                      ? "bg-[#6b2780] text-white cursor-not-allowed"
                      : "hover:bg-[#6b2780] hover:text-white"
                  }`}
                >
                  {state.succeeded ? "SUBMITTED" : "SUBMIT"}
                </button>
              </div>
            </form>
            {state.succeeded ? (
              <div className="flex items-center justify-center text-center  md:justify-start md:pl-48 lg:pl-0 pt-6">
                <p className="text-lg">
                Thank you for reaching out to us! Your message has been successfully submitted. Our team is now reviewing your inquiry and will get back to you shortly. In the meantime, feel free to explore our website for more information about our products and services.
                </p>
              </div>
            ) : null}
          </div>
        </div>
      </section>
      <section>
        <div className="md:p-24 p-8 grid grid-rows-1 md:grid-cols-3 text-sm gap-8">
          <div className="flex flex-col justify-between">
            <p className="mb-4">
              If you are a private individual or represent a private client and
              would like to enquire about the services we provide, please
              complete the private client enquiry.
            </p>
            <div className="flex gap-2 items-center group">
              <a href="" className="hover:text-blue-500 group">
                Private client enquiry
              </a>
              <hr className="w-6 bg-black h-[2px] group group-hover:w-12 group-hover:origin-left group-hover:transition-all" />
            </div>
          </div>
          <div className="flex flex-col justify-between">
            <p className="mb-4">
              If you are a government representative and would like to discuss a
              possible engagement with our firm, please complete the government
              enquiry.
            </p>
            <div className="flex gap-2 items-center group">
              <a href="" className="hover:text-blue-500 group">
                Government enquiry
              </a>
              <hr className="w-6 bg-black h-[2px] group group-hover:w-12 group-hover:origin-left group-hover:transition-all" />
            </div>
          </div>
          <div className="flex flex-col justify-between">
            <p className="mb-4">
              If you are looking for a career opportunity at Everidoor, please
              visit the jobs and careers page.
            </p>
            <div className="flex gap-2 items-center group">
              <a href="" className="hover:text-blue-500 group">
                Careers at Everidoor
              </a>
              <hr className="w-6 bg-black h-[2px] group group-hover:w-12 group-hover:origin-left group-hover:transition-all" />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Contact;
